import { animateScroll as scroll } from 'react-scroll';
import styled from '@emotion/styled';

import { ScrollArrowIcon } from '@app/components/icons/ArrowIcons';

const ScrollDownArrow = ({
  isScrolled,
  width = '20px',
}: {
  isScrolled: boolean;
  width?: string;
}) => {
  const scrollElementToView = (isScrolled: boolean) => {
    scroll.scrollTo(isScrolled ? 0 : window.innerHeight / 2);
  };
  return (
    <ArrowIconWrapper onClick={() => scrollElementToView(isScrolled)}>
      <StyledScrollArrowIcon
        width={width}
        isScrolled={isScrolled}
        direction={null}
      />
    </ArrowIconWrapper>
  );
};

const StyledScrollArrowIcon = styled(ScrollArrowIcon)<{ isScrolled: boolean }>`
  transition: transform 0.4s ease-in-out;
  transform: rotate(${props => (props.isScrolled ? '90' : '270')}deg);
  width: 11px;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: ${props => props.width};
  }
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 16px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  opacity: 0.45;
  margin-bottom: 15px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
`;

export default ScrollDownArrow;
