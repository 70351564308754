import { InView } from 'react-intersection-observer';
import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { StudentSplashPage } from '@app/api/resources/Student';

import { getImageUrl } from '@app/services/images';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';
import ScrollDownArrow from '@app/components/buttons/ScrollDownArrow';
import ImageBase from '@app/components/ImageBase';
import HeaderContainer from '@app/components/layout/HeaderContainer';
import PageSection from '@app/components/layout/PageSection';
import ResponsiveBackgroundImage from '@app/components/responsive-images/ResponsiveBackgroundImage';
import StudentsSplashCTA from '@app/components/students-splash/StudentsSplashCTA';
import CTADescription, {
  OldPrice,
} from '@app/components/students-splash/StudentsSplashCTADescription';
import TranslateHtml from '@app/components/TranslateHtml';

type StudentsSplashHeadProps = {
  studentSplashPage: StudentSplashPage;
};

const StudentsSplashHead = ({ studentSplashPage }: StudentsSplashHeadProps) => {
  const { t } = useTranslation();
  const { regularMonthPrice, studentMonthPrice, geoLocation } = useAppSelector(
    state => ({
      regularMonthPrice: state.user.subscriptionPlans?.month?.price,
      studentMonthPrice: state.user.subscriptionPlans?.month_student?.price,
      geoLocation: state.user?.geoLocation,
    }),
    shallowEqual,
  );

  const desktopBackground =
    studentSplashPage.background_image_url ||
    getImageUrl('/static/images/splash/TheAfricanDesperate.jpg');

  const mobileBackground =
    studentSplashPage.mobile_background_image_url ||
    getImageUrl('/static/images/splash/TheAfricanDesperate_mobile.jpg');

  return (
    <InView initialInView threshold={1}>
      {({ ref, inView }) => (
        <FullHeight ref={ref}>
          <ValueAtBreakpoint
            defaultValue={null}
            valueAtMobile={mobileBackground}
            valueAtTablet={desktopBackground}
            valueAtDesktop={desktopBackground}
            valueAtWide={desktopBackground}
          >
            {({ currentValue: imageUrl }) => (
              <StyledResponsiveBackgroundImage
                imageUrl={imageUrl as string}
                imageHeight="100%"
                coverOrContain="cover"
                darkenImage={studentSplashPage.light_background}
              >
                <HeaderContainer variant="simplified" />
                <Container data-testid="StudentsSplashHead">
                  <PageSection mobilePadding>
                    {studentSplashPage?.logo_url ? (
                      <SplashPartnerLogoContainer>
                        <SplashPartnerLogo
                          src={studentSplashPage.logo_url}
                          alt={studentSplashPage.partner_name}
                        />
                      </SplashPartnerLogoContainer>
                    ) : studentSplashPage?.display_name ? (
                      <SplashPartnerContainer>
                        <p>
                          {t('splash:splash.student.in_partnership_with', {
                            partner: studentSplashPage?.display_name,
                          })}
                        </p>
                      </SplashPartnerContainer>
                    ) : null}

                    <SplashDetailsContainer>
                      <p>
                        {t('splash:splash.student.line1', {
                          days: studentSplashPage?.free_trial_period,
                        })}
                      </p>
                      <p>{t('splash:splash.student.line2')}</p>
                      <p>{t('splash:splash.student.line3')}</p>
                    </SplashDetailsContainer>
                    <SplashCTAContainer>
                      <StudentsSplashCTA
                        ctaCopy={t('splash:splash.student.cta_button')}
                        onboardingContext={{
                          flow: 'studentNew',
                          contextData: {
                            student_splash: {
                              partnerId: studentSplashPage.vanity_path,
                              trialDays: studentSplashPage.free_trial_period,
                            },
                          },
                        }}
                      />
                      <CTADescription isInHeader>
                        <TranslateHtml
                          t={t}
                          i18nKey="splash:splash.student.cta_description"
                          i18nVars={{
                            old_price: (
                              <OldPrice isInHeader>
                                {regularMonthPrice}
                              </OldPrice>
                            ),
                            new_price: <span>{studentMonthPrice}</span>,
                          }}
                        />
                        {geoLocation === 'AR'
                          ? ` ${t('splash:splash.student.tax_argentina')}`
                          : null}
                      </CTADescription>
                    </SplashCTAContainer>
                  </PageSection>
                </Container>
                <ArrowContainer>
                  <ScrollDownArrow isScrolled={!inView} width="15px" />
                </ArrowContainer>
              </StyledResponsiveBackgroundImage>
            )}
          </ValueAtBreakpoint>
        </FullHeight>
      )}
    </InView>
  );
};

const FullHeight = styled.div`
  min-height: 550px;
  height: 100vh;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: 100vh;
    min-height: 750px;
  }
`;

const StyledResponsiveBackgroundImage = styled(ResponsiveBackgroundImage)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    min-height: 650px;
  }
`;

const Container = styled.div`
  color: ${props => props.theme.color.white};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
`;

const SplashPartnerLogoContainer = styled.div`
  max-width: 240px;
  height: auto;
  margin-bottom: 16px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    max-width: 320px;
    height: auto;
    margin-bottom: 72px;
  }
`;

const SplashPartnerLogo = styled(ImageBase)`
  width: 100%;
  height: 100%;
`;

const SplashPartnerContainer = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 24px;
    line-height: 61px;
  }
`;

const SplashDetailsContainer = styled.div`
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.color.white};
  margin-bottom: 61px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 58px;
    line-height: 61px;
    margin-bottom: 96px;
  }
`;

const SplashCTAContainer = styled.div`
  & > :first-child {
    margin-bottom: 14px;
    @media (min-width: ${props => props.theme.mqNew.tablet}) {
      margin-bottom: 25px;
      min-width: 400px;
    }
  }
`;

const ArrowContainer = styled.div`
  padding: 10px 0;
`;

export default StudentsSplashHead;
