import { color as themeColors } from '@app/themes/mubi-theme';

import Svg from '@app/components/icons/Svg';

type Direction = 'up' | 'down' | 'left' | 'right';

type WidthColorDirectionProps = {
  height?: string;
  width?: string;
  color?: string;
  className?: string;
  direction?: Direction;
};

type WidthColorProps = {
  height?: string;
  width?: string;
  className?: string;
  color?: string;
};

const getTransform = (direction: Direction, initialDirection = 'right') => {
  if (initialDirection === 'right') {
    if (direction === 'down') {
      return 'rotate(90deg)';
    }
    if (direction === 'left') {
      return 'rotate(180deg)';
    }
    if (direction === 'up') {
      return 'rotate(270deg)';
    }
  }
  if (initialDirection === 'left') {
    if (direction === 'up') {
      return 'rotate(90deg)';
    }
    if (direction === 'right') {
      return 'rotate(180deg)';
    }
    if (direction === 'down') {
      return 'rotate(270deg)';
    }
  }

  if (initialDirection === 'down') {
    if (direction === 'left') {
      return 'rotate(90deg)';
    }
    if (direction === 'up') {
      return 'rotate(180deg)';
    }
    if (direction === 'right') {
      return 'rotate(270deg)';
    }
  }
  return null;
};

export const ButtonArrowIcon = ({
  color = themeColors.darkText,
  width = '8px',
  height,
  direction = 'right',
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 8 12"
    fill={color}
    width={width}
    height={height}
    style={{ transform: getTransform(direction) }}
  >
    <path
      d="M5.8 5.8L0 0h1.64l5.8 5.8-5.8 5.8H0l5.22-5.22z"
      fillRule="evenodd"
    />
  </Svg>
);

export const AcuteArrowIcon = ({
  color = themeColors.white,
  width = '12px',
  height,
  direction = 'right',
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 12 20"
    fill={color}
    width={width}
    height={height}
    style={{ transform: getTransform(direction) }}
  >
    <path d="M3.7 9.65l7.23-7.23a1 1 0 0 0 0-1.42l-.7-.7A1 1 0 0 0 8.8.3L.3 8.8c-.24.23-.33.55-.29.86-.04.3.06.62.29.85L8.8 19a1 1 0 0 0 1.42 0l.71-.7a1 1 0 0 0 0-1.43L3.7 9.65z" />
  </Svg>
);

export const ObtuseArrowIcon = ({
  color = themeColors.white,
  width = '10px',
  height,
  direction = 'right',
  className,
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 10 25"
    fill={color}
    width={width}
    height={height}
    style={{ transform: getTransform(direction, 'left') }}
    className={className}
  >
    <path d="M.22 13c-.3-.45-.29-1.04.01-1.5L7.7.27a.6.6 0 0 1 .86-.16l1.18.82c.28.2.36.58.17.86L2.93 12.28 9.9 23.21c.18.29.1.67-.18.86l-1.2.8a.6.6 0 0 1-.85-.18L.22 13z" />
  </Svg>
);

export const ScrollArrowIcon = ({
  color = themeColors.white,
  width = '10px',
  height,
  direction = 'down',
  className,
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 10 25"
    fill={color}
    width={width}
    height={height}
    style={{ transform: getTransform(direction, 'left') }}
    className={className}
  >
    <path d="M.22 13c-.3-.45-.29-1.04.01-1.5L7.7.27a.6.6 0 0 1 .86-.16l1.18.82c.28.2.36.58.17.86L2.93 12.28 9.9 23.21c.18.29.1.67-.18.86l-1.2.8a.6.6 0 0 1-.85-.18L.22 13z" />
  </Svg>
);

export const SelectInputArrowIcon = ({
  color = themeColors.black,
  width = '960px',
  height,
}: {
  height?: string;
  width?: string;
  color?: string;
}) => (
  <Svg viewBox="0 0 960 560" fill={color} width={width} height={height}>
    <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z" />
  </Svg>
);

export const ArrowCarousel = ({
  color = themeColors.white,
  width = '38px',
  height,
  direction = 'right',
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 38 77"
    width={width}
    height={height}
    style={{ transform: getTransform(direction) }}
  >
    <path
      d="M0 1l37.963 37.5L0 76"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </Svg>
);

export const ShowingCarouselArrow = ({
  color = themeColors.darkText,
  width = '13px',
  height,
  direction = 'right',
  className,
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 13 24"
    width={width}
    height={height}
    style={{ transform: getTransform(direction, 'left') }}
    className={className}
  >
    <path
      d="M12 0L1 12l11 12"
      stroke={color}
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </Svg>
);

export const DropDownArrow = ({
  color = themeColors.lightText,
  width = '4180px',
  height,
}: WidthColorProps) => (
  <Svg viewBox="0 0 4180 3620" width={width} height={height}>
    <path
      d="M2062 3253l-848-1413L294 305 191 135l1906-3c1049-1 1908 0 1910 2 3 2-12 30-33 63-87 136-104 163-104 166 0 2-133 224-295 493a97139 97139 0 00-544 907l-150 250-160 266a10582 10582 0 00-160 269l-39 62a25908 25908 0 01-302 505c-59 101-113 185-120 185-4 0-21-21-38-47z"
      fill={color}
    />
  </Svg>
);

export const SelectArrowIcon = ({
  color = themeColors.mubiBlue,
  width = '12px',
  direction = 'down',
  height,
}: WidthColorDirectionProps) => (
  <Svg
    viewBox="0 0 12 7"
    width={width}
    height={height}
    style={{ transform: getTransform(direction, 'down') }}
  >
    <path
      d="M2.12.29L6 4.17 9.88.29a1 1 0 111.41 1.41L6.7 6.29a1 1 0 01-1.41 0L.7 1.7A1 1 0 01.7.29a1.02 1.02 0 011.42 0z"
      fill={color}
      fillRule="nonzero"
    />
  </Svg>
);
