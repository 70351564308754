import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';

import { initialiseTrendingFilms } from '@app/services/page-initialisation/splash';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import PageSection from '@app/components/layout/PageSection';
import RotatingFilmTileGroup from '@app/components/RotatingFilmTileGroup';

const FILM_TILE_ASPECT_RATIO = 0.562;

const FilmJoinWhatsShowing = () => {
  const { t } = useTranslation('film_details');
  const [films, setFilms] = useState<Film[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [caughtRequestError, setCaughtRequestError] = useState(false);
  const { httpContext, country } = useAppSelector(
    state => ({
      httpContext: state?.appState.httpContext,
      country: state?.user?.geoLocation,
    }),
    shallowEqual,
  );
  useEffect(() => {
    try {
      const getTrendingFilms = async () => {
        const trendingFilms = await initialiseTrendingFilms(
          httpContext,
          country,
        );
        setFilms(trendingFilms);
        setIsLoading(false);
      };
      getTrendingFilms();
    } catch (error) {
      setCaughtRequestError(true);
    }
  }, [country]);
  if (caughtRequestError) {
    return null;
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <PageSection>
        <Header>
          {t(
            'film_details:film_details.subscribe_page.what_showing.first_line',
          )}
          <br />
          {t(
            'film_details:film_details.subscribe_page.what_showing.second_line',
          )}
          <br />
          {t(
            'film_details:film_details.subscribe_page.what_showing.third_line',
          )}
        </Header>
        <RotatingFilmTileGroup
          films={films}
          snowplowElement="film_tile"
          showCenteredPlayButtonAndNoHover={false}
        >
          {rotatingFilmTile => (
            <FilmTileContainer>{rotatingFilmTile}</FilmTileContainer>
          )}
        </RotatingFilmTileGroup>
      </PageSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 56px 0;
  background-color: ${props => props.theme.color.lightBackground};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 82px 0;
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.color.lightText};
  text-align: left;
  margin-bottom: 24px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 22px;
    text-align: center;
  }
`;

const FilmTileContainer = styled.div`
  width: calc(100vw - 30px);
  height: calc((100vw - 30px) * ${FILM_TILE_ASPECT_RATIO});
  @media (min-width: ${props => props.theme.mqNew.mobile}) {
    height: ${props => props.theme.grid.standardFilmTileHeight}px;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const Loading = styled.div`
  width: 100%;
  height: 645px;
`;

export default FilmJoinWhatsShowing;
