import styled from '@emotion/styled';

const CTADescription = styled.p<{ isInHeader?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${props =>
    props.isInHeader ? props.theme.color.white : props.theme.color.lightText};
`;

export const OldPrice = styled.span<{ isInHeader?: boolean }>`
  text-decoration: line-through;
  color: ${props =>
    props.isInHeader ? props.theme.color.white : props.theme.color.lightGray};
  opacity: ${props => (props.isInHeader ? 0.5 : '')};
`;

export default CTADescription;
