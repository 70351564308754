import styled from '@emotion/styled';

import { OnboardingContext } from '@app/reducers/AppStateReducer';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import OnboardingSignupCtaButtonContainer from '@app/components/onboarding/OnboardingSignupCtaButtonContainer';

type StudentsSplashCTAProps = {
  ctaCopy?: string;
  onboardingContext?: OnboardingContext;
};

const StudentsSplashCTA = ({
  ctaCopy = null,
  onboardingContext = null,
}: StudentsSplashCTAProps) => {
  return (
    <OnboardingSignupCtaButtonContainer>
      {({ showModal, context, ctaButtonText }) => (
        <SignupButton
          clickAction={() => showModal(onboardingContext || context)}
        >
          {ctaCopy || ctaButtonText}
        </SignupButton>
      )}
    </OnboardingSignupCtaButtonContainer>
  );
};

export default StudentsSplashCTA;

const SignupButton = styled(MubiButton)`
  width: 100%;
  font-size: 16px;
  padding: 12px 25px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 18px;
    width: initial;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 24px;
    padding: 16px 25px;
  }
`;
