import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getImageUrl } from '@app/services/images';

import mubiTheme from '@app/themes/mubi-theme';

import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';

const WatchMoviesSection = () => {
  const { t } = useTranslation('film_details');

  return (
    <PageSection>
      <Container>
        <LeftColumn>
          <Header>
            {t(
              'film_details:film_details.subscribe_page.watch_movies.beautiful',
            )}
          </Header>
          <Description>
            {t(
              'film_details:film_details.subscribe_page.watch_movies.stream_or_download',
            )}
            <br />
            {t(
              'film_details:film_details.subscribe_page.watch_movies.any_screen',
            )}
          </Description>
          <DevicesPictureMobile>
            <source
              srcSet={getImageUrl(
                '/static/images/subscribe/film/devices.png',
                644,
              )}
              media={`(min-width: ${mubiTheme.mqNew.tablet})`}
            />
            <ImageBase
              alt={t(
                'film_details:film_details.subscribe_page.watch_movies.any_screen',
              )}
              src={getImageUrl(
                '/static/images/subscribe/film/devices.png',
                360,
              )}
            />
          </DevicesPictureMobile>
          <AvailableOn>
            {t(
              'film_details:film_details.subscribe_page.watch_movies.mobile_tv',
            )}
          </AvailableOn>
          <Devices>
            <DeviceImg
              loading="lazy"
              mobileWidth="73px"
              desktopWidth="80px"
              alt="Download on the App Store"
              src={getImageUrl(
                '/static/images/subscribe/film/apple-store.png',
                100,
              )}
            />
            <DeviceImg
              loading="lazy"
              hiddenOnMobile
              mobileWidth=""
              desktopWidth="80px"
              alt="Get it on Google Play"
              src={getImageUrl(
                '/static/images/subscribe/film/google-play.png',
                100,
              )}
            />
            <AlignBottomSection>
              <DeviceImg
                loading="lazy"
                mobileWidth="45px"
                desktopWidth="45px"
                alt="tvOS"
                src={getImageUrl('/static/images/subscribe/film/tvOS.png', 100)}
              />
              <DeviceImg
                loading="lazy"
                mobileWidth="92px"
                desktopWidth="92px"
                alt="androidtv"
                src={getImageUrl(
                  '/static/images/subscribe/film/androidtv.png',
                  100,
                )}
              />
              <Ellipses>...</Ellipses>
            </AlignBottomSection>
          </Devices>
        </LeftColumn>

        <RightColumn>
          <DevicesImgDesktop
            loading="lazy"
            src={getImageUrl('/static/images/subscribe/film/devices.png', 800)}
          />
        </RightColumn>
      </Container>
    </PageSection>
  );
};

const Container = styled.div`
  padding: 60px 0 0;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: flex;
    justify-content: space-between;
    padding: 90px 0 84px;
  }
`;

const LeftColumn = styled.div``;
const RightColumn = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: ${props => props.theme.color.black};

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 411px;
    font-size: 34px;
    line-height: 36px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  color: ${props => props.theme.color.lightText};
  margin-bottom: 32px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 530px;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 114px;
  }
`;

const AvailableOn = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.darkGray};
  letter-spacing: 0;
  text-align: center;
  margin: 64px 0 8px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin: 0 0 8px;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0;
  }
`;

const DeviceImg = styled(ImageBase)<
  React.ImgHTMLAttributes<HTMLImageElement> & {
    mobileWidth: string;
    desktopWidth: string;
    hiddenOnMobile?: boolean;
  }
>`
  align-self: center;
  width: ${props => props.mobileWidth};
  height: auto;
  ${props => props.hiddenOnMobile && 'display: none;'}

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: block;
    width: ${props => props.desktopWidth};
  }
`;

const Devices = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > ${DeviceImg} {
    margin-right: 16px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

const DevicesPictureMobile = styled.picture`
  display: block;
  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

const DevicesImgDesktop = styled(ImageBase)`
  width: 100%;
  height: auto;
  align-self: flex-end;
  max-width: 725px;
`;

const AlignBottomSection = styled.div`
  display: flex;
  align-items: flex-end;
  & > ${DeviceImg} {
    margin-right: 16px;
  }
`;

const Ellipses = styled.div`
  font-size: 16px;
  line-height: 14px;
  color: ${props => props.theme.color.lightText};
  letter-spacing: 0;
  text-align: center;
`;

export default WatchMoviesSection;
