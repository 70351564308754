import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import {
  getStudentSplashPageForVanityPath,
  StudentSplashPage,
} from '@app/api/resources/Student';

import { Context } from '@app/types/common';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import FilmJoinWhatsShowing from '@app/components/film-join/FilmJoinWhatsShowing';
import WatchMoviesSection from '@app/components/film-join/WatchMoviesSection';
import PageSection from '@app/components/layout/PageSection';
import SocialMetaTags from '@app/components/SocialMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';
import StudentsSplashCTA from '@app/components/students-splash/StudentsSplashCTA';
import CTADescription, {
  OldPrice,
} from '@app/components/students-splash/StudentsSplashCTADescription';
import StudentsSplashHead from '@app/components/students-splash/StudentsSplashHead';
import TrackScrollEventWhenInView from '@app/components/TrackScrollEventWhenInView';
import TranslateHtml from '@app/components/TranslateHtml';

type StudentSplashPageProps = { studentSplashPage: StudentSplashPage };

const scrollData = {
  scroll_type: 'page_scroll',
  scroll_direction: 'vertical',
  page_type: 'student_splash',
};

const StudentSplash = ({ studentSplashPage }: StudentSplashPageProps) => {
  const { t } = useTranslation('splash');
  const { regularMonthPrice, studentMonthPrice, geoLocation } = useAppSelector(
    state => ({
      regularMonthPrice: state.user.subscriptionPlans?.month?.price,
      studentMonthPrice: state.user.subscriptionPlans?.month_student?.price,
      geoLocation: state.user?.geoLocation,
    }),
    shallowEqual,
  );

  const pageTitle = t('splash:splash.student.share_title', {
    partner: studentSplashPage.display_name || studentSplashPage.partner_name,
  });

  return (
    <>
      <StandardHeadTags title={pageTitle} noIndex />
      <SocialMetaTags
        title={pageTitle}
        image={
          studentSplashPage.background_image_url || studentSplashPage.logo_url
        }
        description={t('splash:splash.student.share_description', {
          days: studentSplashPage?.free_trial_period,
        })}
        twitter
        twitterCard="summary_large_image"
      />
      <StudentsSplashHead studentSplashPage={studentSplashPage} />
      <TrackScrollEventWhenInView
        threshold={0.5}
        scrollDetail={{
          description: 'Viewed devices section',
          reached_end: false,
          ...scrollData,
        }}
      >
        <WatchMoviesSection />
      </TrackScrollEventWhenInView>
      <TrackScrollEventWhenInView
        threshold={0.5}
        scrollDetail={{
          description: 'Viewed whats showing section',
          reached_end: false,
          ...scrollData,
        }}
      >
        <FilmJoinWhatsShowing />
      </TrackScrollEventWhenInView>
      <PageSection>
        <MobileJoinCTA>
          <StudentsSplashCTA
            ctaCopy={t('common:common.subscribe_ctas.try_N_days_free', {
              days: studentSplashPage?.free_trial_period,
            })}
            onboardingContext={{
              flow: 'studentNew',
              contextData: {
                student_splash: {
                  partnerId: studentSplashPage.vanity_path,
                  trialDays: studentSplashPage.free_trial_period,
                },
              },
            }}
          />
          <CTADescription>
            <TranslateHtml
              t={t}
              i18nKey="splash:splash.student.cta_description"
              i18nVars={{
                old_price: <OldPrice>{regularMonthPrice}</OldPrice>,
                new_price: <span>{studentMonthPrice}</span>,
              }}
            />
            {geoLocation === 'AR'
              ? ` ${t('splash:splash.student.tax_argentina')}`
              : null}
          </CTADescription>
        </MobileJoinCTA>
      </PageSection>
      <PageSection>
        <TrackScrollEventWhenInView
          scrollDetail={{
            description: 'Viewed Footer',
            reached_end: true,
            ...scrollData,
          }}
        >
          <Footer>
            <TranslateHtml
              t={t}
              i18nKey="splash:splash.footer.recaptcha.copy"
              i18nVars={{
                privacy_link: (
                  <a href="https://policies.google.com/privacy">
                    {t('splash:splash.footer.recaptcha.privacy')}
                  </a>
                ),
                terms_link: (
                  <a href="https://policies.google.com/terms">
                    {t('splash:splash.footer.recaptcha.terms')}
                  </a>
                ),
              }}
            />
          </Footer>
        </TrackScrollEventWhenInView>
      </PageSection>
    </>
  );
};

type StudentInitialProps =
  | { studentSplashPage: StudentSplashPage }
  | { errorStatusCode: number };

StudentSplash.getInitialProps = async (
  ctx: Context,
): Promise<StudentInitialProps> => getInitialProps(ctx);

export const getInitialProps = async (
  ctx: Context,
): Promise<StudentInitialProps> => {
  const { store, query } = ctx;
  const currentState = store.getState();
  const httpContext = currentState?.appState?.httpContext;

  const studentVanityPath = query.studentVanityPath || query.vanityPath;

  try {
    const { data: studentSplashPage } = await getStudentSplashPageForVanityPath(
      httpContext,
      studentVanityPath as string,
    );

    return { studentSplashPage };
  } catch (error) {
    if (error?.status === 404) {
      return {
        errorStatusCode: 404,
      };
    }
    Sentry.captureException(error, {
      extra: {
        studentVanityPath: query.studentVanityPath,
      },
    });
    throw error;
  }
};

export default StudentSplash;

const MobileJoinCTA = styled.div`
  display: block;

  & > :first-child {
    margin: 30px auto 14px;
  }

  & > :last-child {
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: none;
  }
`;

const Footer = styled.div`
  padding: 24px 20px;
  font-size: 12px;
  text-align: center;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 32px 0;
  }
`;
