import HttpService from '@app/api/http-service';
import { ObjectOfStrings } from '@app/api/utility-types';

export type StudentSplashPage = {
  id: number;
  partner_name: string;
  vanity_path: string;
  free_trial_period: number;
  category: string;
  live: boolean;
  display_name: string;
  light_background: boolean;
  background_image_url: string;
  mobile_background_image_url: string;
  logo_url: string;
};

export const getStudentSplashPageForVanityPath = (
  httpContext: ObjectOfStrings,
  vanityPath: string,
): Promise<{ data: StudentSplashPage }> =>
  HttpService(httpContext).get(`/student_splash_partners/${vanityPath}`);
